<template lang="pug">
  div.fill-height(v-if="$store.state.routing.currentIndex > -1")
    v-toolbar(dense, flat)
      v-toolbar-title Route Setup
      v-spacer
    div()
      v-alert(v-if="errors.length" :value="true" color="error" icon="warning")
        div(v-for="(e,index) in errors" :key="index" v-text="e")

      v-layout(justify-end)
        v-btn.mx-2(small text color="grey" @click="cancel") cancel
        v-btn.mx-2(small color="primary" @click="save" :disabled="!valid") Save
    .mx-8.mt-8
      v-card
        .mx-4(grid-list-xl)
          v-row()
            v-col(cols="12" xs="12" md="6" xl="6")
              v-row(align="end")
                v-col(cols="12" xs="12" md="4")
                  v-text-field(label='Name of Route' v-model='name' :hide-details='false' :rules='[rules.required]' required)
                v-col(cols="12" xs="12" md="4")
                  v-text-field(label='Description' v-model='description', :hide-details='false')
                v-col(cols="12" xs="12" md="4")
                  workflow-auto-complete.z100(v-model='workflow_id'  label="Attach Workflow Form" placeholder="Workflow Form")
              v-row(align="end")
                v-col(cols="12" xs="12" md="6")
                  vehicle-auto-complete.z100(v-model='vehicle_id'  label="Select Vehicle*" placeholder="Vehicle to View")
                v-col(cols="12" xs="12" md="6")
                  auto-date-time-picker.z100(v-model="start_date_time", labelDate="Start Date", labelTime="Start Time")

              form-divider
              v-row()
                v-col(cols="12" xs="12" md="6")
                  place-auto-complete.z100(v-model="start_place_id" label="Start Location"  :is_hub="true" )
                v-col(cols="12" xs="12" md="6")
                  place-auto-complete.z100(v-model="end_place_id" label="End Location"  :is_hub="true")


              route-status-bar.pb-4(v-if="currentPathCoordinates" @toggleLock="onToggleLock" @toggleDialog="onToggleDialog" @toggleSelectPlacesDialog="onToggleSelectPlacesDialog" :expand="showDialog" :vehicle_id="vehicle_id")
              div(ref="pageWaypoint" )
                waypoint-container(@calculate="onCalculate()" :style="{height:`${getPageWaypointHeight}px`}" )

            v-col(cols="12" xs="12" md="6" xl="6")
              //- .flex(v-for="(item,index) in $store.state.routing.selectedPlaces" :key="index") {{item.id}} - {{item.name}}
              div.fill-height(v-if="contactsLoaded && customersLoaded && merchantsLoaded")
                 route-setup-map(v-if="currentPathCoordinates"  :key="mapkey" @done="onMapDone") //- mode is either web or map
    v-dialog(v-model="showDialog"  max-width="800" persistent)
      v-card( :style="{height:`${$store.state.main_height-90}px`,overflow: 'hidden'}")
        div.py-4.px-4()
          route-status-bar.pb-4(v-if="currentPathCoordinates" @toggleLock="onToggleLock" @toggleDialog="onToggleDialog" @toggleSelectPlacesDialog="onToggleSelectPlacesDialog" :expand="showDialog" :vehicle_id="vehicle_id")
          waypoint-container(@calculate="onCalculate()"  :style="{height:`${$store.state.main_height-180}px`}" )
    v-dialog.white(v-model="selectPlacesDialog" max-width="500")
      v-card()
        v-card-title.grey.lighten-2()
          v-text-field.mr-3(v-model='search' label='Search Filter', persistent-hint, required, :prepend-icon="'search'" clearable  @click:clear="clearSearch" )
          v-btn(@click="onSearch" color="primary" small) Search
        div.white(v-if="selectPlacesDialog"  )
          div.placeListContainer( :style="{height:`${$store.state.main_height-140}px`}")
            v-list-item-group.white()
              v-list-item.white.px-4(v-for="(place,index) in allPlaces" :key="index"  dense)
                v-layout.dashboardLine()
                  v-checkbox.mt-3(v-model="place.selected" @change="switchSelectedPlace(place)")
                  v-list-item-content
                    v-list-item-title() {{place.name}} -
                      span.grey--text() {{place.description}}
                    v-list-item-subtitle()
                      .primary--text.caption(v-if="place.customers") {{place.customers}}
                  .d-flex.align-center(v-if="place.selected")
                    v-btn(fab x-small elevation="0")
                      v-icon(@click="duplicatePlace(place)") content_copy





</template>

<script>
import FormDivider from "@/modules/global/components/forms/FormDivider";
import VehicleAutoComplete from "@/modules/global/components/forms/VehicleAutoComplete";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";



import AutoDateTimePicker from "@/modules/global/components/forms/AutoDateTimePicker";
import {createHelpers} from "vuex-map-fields";
import WorkflowAutoComplete from "@/modules/global/components/forms/WorkflowAutoComplete";
import WaypointContainer from "@/modules/places/components/ui/WaypointContainer";
import RouteSetupMap from "@/modules/places/components/ui/RouteSetupMap";
import PlaceAutoComplete from "@/modules/global/components/forms/PlaceAutoComplete";
import RouteStatusBar from "@/modules/places/components/ui/RouteStatusBar";
import Repository from "@/repository";
import {searchMixin} from "@/mixin/searchListMixin";

const {mapFields} = createHelpers({
  getterType: "routing/getRouting",
  mutationType: "routing/updateRouting",
});
export default {
  components: {
    RouteStatusBar,
    PlaceAutoComplete,
    WorkflowAutoComplete,
    VehicleAutoComplete,
    WaypointContainer,

    FormDivider,
    AutoDateTimePicker,
    RouteSetupMap
  },
  mixins: [searchMixin],
  data: () => ({
    search: "",
    showDialog:false,
    selectPlacesDialog:false,
    previousWorkflowID:null,
    valid: true,
    error: false,
    errors: [],
    rules: {
      required: value => !!value || "Required.",
    },
    mapkey:0,
    allPlaces:[],
    originalAllPlaces:null,
    contactsLoaded:false,
    customersLoaded:false,
    merchantsLoaded:false

  }),

  mounted() {

    if (this.$store.state.contacts.contact_data.length < 2) {
      this.$store.dispatch("contacts/loadAllContacts").then(() => {
        this.contactsLoaded = true;
      });
    } else {
      this.contactsLoaded = true;
    }

    if (this.$store.state.customer.customer_data.length < 2) {
      this.$store.dispatch("customer/loadAllCustomers").then(() => {
        this.customersLoaded = true;
      });
    } else {
      this.customersLoaded = true;
    }

    if (this.$store.state.merchant.merchant_data.length < 2) {
      this.$store.dispatch("merchant/loadAllMerchants").then(() => {
        this.merchantsLoaded = true;
      });
    } else {
      this.merchantsLoaded = true;
    }


    if (this.$store.state.routing.currentIndex == -1) {
      this.$store.dispatch("routing/fetchRouting",this.$route.params.placeID).then(()=>{
        console.log(this.$store.getters["routing/getCurrentRouting"])
        this.onCalculate();


      }).catch(()=>{
        this.$router.push({name: "places", params: {tabID: 0}}).catch(()=>{});
      })
    } else {
      if(this.currentRouting.order_details != null){
        this.$store.commit("routing/setSelectedPlaces",this.currentRouting.order_details)
      }

      this.previousWorkflowID = this._.cloneDeep(this.workflow_id);
      this.onCalculate();
    }





  },
  computed: {
    ...mapFields([
      "vehicle_id",
      "workflow_id",
      "start_date_time",
      "places_id",
      "details",
      "order_details",
      "name",
      "description",
      "locked",
    ]),

    start_place_id:{
      get:function(){
        if(this.currentRouting.order_details != null && this.currentRouting.order_details.length > 0 && this.currentRouting.order_details[0].id){
          return this.currentRouting.order_details[0].id;
        }
        return null
      },
      set:function(value){

        if(value == null){
          if(this.currentRouting.order_details.length > 0 && this.currentRouting.order_details[0].id){
            this.$store.commit("routing/deleteSelectedByIndex",0);
            this.onCalculate()
            return
          }
        }
        let place = this.$store.getters["place/getPlaceByID"](value);
        this.$store.commit("routing/unshiftIntoSelected",place)
        this.onCalculate();
      }
    },

    end_place_id:{
      get:function(){
        console.log("current routtinggggg")
        console.log(this.currentRouting)
        if(this.currentRouting.order_details != null && this.currentRouting.order_details.length > 0 && this.currentRouting.order_details[this.currentRouting.order_details.length-1].id){
          return this.currentRouting.order_details[this.currentRouting.order_details.length-1].id;
        }
        return null
      },
      set:function(value){
        if(value == null){
          if(this.currentRouting.order_details.length > 0 && this.currentRouting.order_details[this.currentRouting.order_details.length-1].id){
            this.$store.commit("routing/deleteSelectedByIndex",this.currentRouting.order_details.length-1);
            this.onCalculate();
            return
          }
        }
        let place = this.$store.getters["place/getPlaceByID"](value);
        this.$store.commit("routing/pushIntoSelected",place)
        this.onCalculate();
      }
    },


    currentPathCoordinates() {
      return this.$store.getters["routing/getCurrentPathCoordinates"];
    },

    currentRouting() {
      return this.$store.getters["routing/getCurrentRouting"];
    },

    getPageWaypointHeight() {
      return this.$store.state.main_height - 440
    },

    orderDetails:{
      get:function(){
        return this.$store.getters["routing/getCurrentRouting"].order_details
      }
    },



  },

  methods: {



    duplicatePlace(item){
      if(item.selected){
        this.switchSelectedPlace(item)
      }
    },



    getPlaces() {
      let tempPlaces = [];
      if(this.$store.state.place.currentIndex == -1){
        this.$store.dispatch("place/loadAllPlaces").then(()=>{
          tempPlaces = JSON.parse(JSON.stringify(this.$store.state.place.place_data));
        })
      }else{
        tempPlaces = JSON.parse(JSON.stringify(this.$store.state.place.place_data));
      }
      tempPlaces.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

      this.allPlaces = [];
      // console.log(this.orderDetails)
      tempPlaces.forEach(item => {
        let obj = {};
        obj.name = item.name;
        obj.id = item.id;
        obj.description = item.description;
        obj.selected = this.orderDetails.findIndex( o => o.id === item.id) > -1;
        obj.customers = null
        if (item.customers_id != null) {
          obj.customers = ""
          item.customers_id.forEach(id => {
            let result = this.$store.getters["customer/getCustomerByID"](id);
            if (typeof(result) != "undefined" && result.name != null && result.name !== "") {
              obj.customers +=  result.name + ", ";
            }
          })
        }


        obj.contacts_id = item.contacts_id;
        obj.merchants_id = item.merchants_id;

        this.allPlaces.push(obj);
      })

      this.originalAllPlaces = this.allPlaces;
    },

    switchSelectedPlace:function(item){
      console.log(item);
      if(item.selected == true){
        let place = this.$store.getters["place/getPlaceByID"](item.id)
        if(typeof place != 'undefined'){
          this.$store.commit("routing/unshiftIntoSelected",place)
        }
      }else{
        if(this.$store.state.routing.selectedPlaces.length <= 2){
          this.$store.commit("showSnackBar", {
            color:this.$vuetify.theme.themes.light.primary,
            message: `At least 2 items need to be selected`
          });

        }else{
          this.$store.commit("routing/deleteFromSelected",item.id)
        }

      }
      this.onCalculate();
    },

    onCalculate: function () {
      console.log("CALCULATING")
      if(this.places_id == null && this.$store.state.routing.selectedPlaces.length == 0){
        console.log("returned empty")
        return;
      }


      let osrmObj = {};




      osrmObj.places_id = [];

      if(this.currentRouting == null){
        osrmObj.locked = true;
      }else{
        osrmObj.locked = this.currentRouting.locked
      }
      // check if places_id exists in the object
      if(this.places_id != null && this.places_id.length > 0){
        osrmObj.places_id = this.places_id;
      }else{
        // places does not exists however items are selected
        if(this.$store.state.routing.selectedPlaces.length > 0){
          this.$store.state.routing.selectedPlaces.forEach((element) =>{
            osrmObj.places_id.push(element.id)
          })
          this.$store.dispatch("routing/loadTSP", osrmObj).then(() => {
            if (this.$store.state.routing.tspResponse.tsp_details.code === "Ok") {
              this.processTSP(this.$store.state.routing.tspResponse);
            }
          })

        }
      }
    },

    processTSP(tspResponse){
      console.log(tspResponse)
      // only save the ids an not the places
      let tempPlaceArray = JSON.parse(JSON.stringify(this.$store.state.routing.selectedPlaces));

      // placeArray only stores the id of the place
      let placeArray = [];
      tempPlaceArray.forEach((element) => {
        placeArray.push({id: element.id});
      })



      console.log(tspResponse.tsp_details);

      for (var i=0; i<placeArray.length; i++) {
        if(tspResponse.locked == true){
          placeArray[i].waypoint_index = tspResponse.tsp_details.waypoints[i].waypoint_index;
        }
        placeArray[i].street = tspResponse.tsp_details.waypoints[i].name;
        placeArray[i].distance = tspResponse.tsp_details.waypoints[i].distance;
        placeArray[i].order_id = i;
        placeArray[i].place_id = placeArray[i].id;
        placeArray[i].place = this.$store.getters["place/getPlaceByID"](placeArray[i].id); // dynamicchrom
        if (i > 0) {
          //tspResponse.details.waypoints[i].waypoint_index
          if(tspResponse.locked == true) {
            placeArray[i].leg_distance = tspResponse.tsp_details.trips[0].legs[tspResponse.tsp_details.waypoints[i].waypoint_index - 1].distance;
            placeArray[i].leg_duration = tspResponse.tsp_details.trips[0].legs[tspResponse.tsp_details.waypoints[i].waypoint_index - 1].duration;
          }else{
            placeArray[i].leg_distance = tspResponse.tsp_details.routes[0].legs[placeArray[i].order_id - 1].distance;
            placeArray[i].leg_duration = tspResponse.tsp_details.routes[0].legs[placeArray[i].order_id - 1].duration;
          }

        }
      }


      if(tspResponse.locked == true) {
        // sort TRIP in ascending according to waypoints
        placeArray = placeArray.sort((a,b) => a.waypoint_index - b.waypoint_index)
      }else{
        // sort ROUTE in ascending order according to order id
        placeArray = placeArray.sort((a,b) => a.order_id - b.order_id)
      }


      this.$store.commit("routing/updateRouting",{path:"order_details",value:placeArray});
      this.$store.commit("routing/updateRouting",{path:"tsp_details",value:tspResponse.tsp_details});
      this.$store.commit("routing/updateRouting",{path:"locked",value:tspResponse.locked});
    },


    onToggleLock:function(){
      this.$store.commit("routing/updateRouting",{path:"locked",value:!this.currentRouting.locked});
      this.$store.commit("routing/updateRouting",{path:"tsp_details",value:null});
      this.onCalculate();
    },

    onChangeOrder:function(){
      console.log(this.currentRouting);
      // todo am stuck here
      // this.$store.dispatch("routing/loadTSP", this.currentRouting).then(() => {
      //   if (this.$store.state.routing.tspResponse.tsp_details.code === "Ok") {
      //     this.processTSP(this.$store.state.routing.tspResponse);
      //   }
      // })
    },


    onToggleDialog:function(){
      this.showDialog = !this.showDialog;
    },


    onToggleSelectPlacesDialog:function(){
      if(this.allPlaces.length == 0){
        this.getPlaces();
      }
      this.selectPlacesDialog = !this.selectPlacesDialog
    },







    onMapDone:function(value){

      let base64ContentArray = value.split(",")
      let mimeType = base64ContentArray[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0]
      let base64Data = base64ContentArray[1]

      let postObj = {
        "type":mimeType,
        "name":"route_map",
        "data": base64Data
      }
      Repository.post("/upload/blob/base64",postObj).then( response =>{
        console.log(response.data)
        this.$emit('input',`/media/${response.data.account_id}/${response.data.file_name}`)
        this.$emit("closeDialog")
      })


      console.log(value);
      // this.mapkey += 1;
    },

    cancel: function () {
      // this.$store.commit("routing/reset");
      this.$store.dispatch("routing/loadAllRoutings").then(()=>{
        this.$router.push({name: "places", params: {tabID: 1}}).catch(()=>{});
      })

    },

    save: function () {

      this.$store.dispatch("routing/saveRouting").then(() => {

       this.$store.dispatch("routing/loadAllRoutings").then(()=>{
         this.$router.push({name: "places", params: {tabID: 1}}).catch(()=>{});
       })

      } );
    },

    addPlaceAndRouteFieldsToWorkflowForm:function(){
      // save modifications to workflow
      if(this.workflow_id != null){
        this.$store.commit("workflow/setCurrentIndexByID", this.workflow_id);
        // check if the workflow already has a route
        if(this.$store.getters["workflow/getCurrentWorkflow"].details.route == null){
          // set the form Builder and add Route and Place
          this.$store.commit("workflow/formBuilder/setSchema",this.$store.getters["workflow/getLatestSchema"])
          this.$store.commit("workflow/formBuilder/addNewField","RouteID");
          this.$store.commit("workflow/formBuilder/addNewField","RouteName");
          this.$store.commit("workflow/formBuilder/addNewField","PlaceID");
          this.$store.commit("workflow/formBuilder/addNewField","PlaceName");
          // commit the new schema
          this.$store.commit(
              "workflow/setSchema",
              {schema: this.$store.getters["workflow/formBuilder/getSchema"], version:this.$store.getters["workflow/getCurrentWorkflow"].version+1, date:new Date().toISOString()}
          );

        }
        // set the osrm_id as the route in the workflow
        this.$store.commit("workflow/setRouteCurrentWorkflow",this.$route.params.placeID);
        // save to server the new worklfow
        this.$store.dispatch("workflow/saveWorkflow").then(()=>{
          this.goBackToList();
        })

        // get the latest schema and duplicate it with the addition of route and place
      }else{
        // there is no workflow_id thus remove the route and place
        console.log(this.previousWorkflowID);
        if(this.previousWorkflowID){
          this.$store.commit("workflow/setCurrentIndexByID", this.previousWorkflowID);
          // set the form Builder and add Route and Place
          this.$store.commit("workflow/formBuilder/setSchema",this.$store.getters["workflow/getLatestSchema"])
          this.$store.commit("workflow/formBuilder/removeField","RouteID");
          this.$store.commit("workflow/formBuilder/removeField","RouteName");
          this.$store.commit("workflow/formBuilder/removeField","PlaceID");
          this.$store.commit("workflow/formBuilder/removeField","PlaceName");
          this.$store.commit("workflow/formBuilder/setOrderNumbers");
          // commit the new schema
          this.$store.commit(
              "workflow/setSchema",
              {schema: this.$store.getters["workflow/formBuilder/getSchema"], version:this.$store.getters["workflow/getCurrentWorkflow"].version+1, date:new Date().toISOString()}
          );

          // set the osrm_id as the route in the workflow
          this.$store.commit("workflow/setRouteCurrentWorkflow",null);
          // save to server the new worklfow
          this.$store.dispatch("workflow/saveWorkflow").then(()=>{
            this.goBackToList();
          })
        }
      }
    },

    goBackToList: function() {
      this.$store.dispatch("routing/loadAllRoutings");
      this.$router.push({name: "places", params: {tabID: 1}}).catch(()=>{});
    },



    onSearch(){
      let patt = new RegExp(this.search.toLowerCase(), "i");
      this.allPlaces = [];
      let isFound  = false;
      for (var i=0; i<this.originalAllPlaces.length; i++){
        // Search the Object
        isFound = false
        isFound = this.searchObject(this.originalAllPlaces[i], patt, isFound);
        if (isFound){
          this.allPlaces.push(this.originalAllPlaces[i]);
        }
      }

    },

    clearSearch() {
      this.allPlaces = this.originalAllPlaces;
    }






  },
};
</script>

<style>
.dateTimeHeader {
}

.dateButton2 {
  width: 90%;
  border-radius: 15px;
  padding: 5px 5px;
  max-width: 400px;
}

.dateButtonContainer {
  display: flex;
  justify-content: center;
}

.mapContainer {
  background-color: #cccccc;
  width: 100%;
  height: 100%;
}
.z100 {
  z-index: 100;
}

.placeListContainer {
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
